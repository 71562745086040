<template>
	<div class="vld-parent">
		<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
                <Breadcrumb :model="rastromenu" />
				<h5>Lista de accesos asignados para: </h5>	
                <h5>{{ login.nombres }} {{login.apellidos}}</h5>			
                <Fieldset v-for="g in grupos" :key="g.id_grupo" class="p-m-3" :legend="g.grupo" :toggleable="true">
                    <div v-for="a in g.accesos" :key="a.id_acceso" class="p-m-2 p-fluid p-inputgroup">
						<InputSwitch v-model="a.asignado" @change="setPermiso($event, a)"/>
						<div class="p-my-2 p-ml-3">{{a.acceso}}</div>                    
					</div>		
				</Fieldset>
            </div>          
		</div>
	</div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import UserService from '../service/user.service';

export default {
	data() {
		return {
			grupos: [],
            permisos: {                  
            },
            rastromenu: [
				{label: 'Usuarios & Permisos', to: '/usuarios'}
			],
			id_login: this.id,
			loginUpdate: 0,
			loading: false,
			fullPageLoading: false,
			login: {
				nombres: '',
				apellidos: '',
				login: ''
			}
		}
	},
	components: {
		Loading
	},
	props: {
		id: {
			type: String,
			required: true
			}			
	},		
	created(){
	},
	mounted()
	{
		this.loading = true;
		UserService.getLoginXUsuario(this.id).then(data => {
			this.loading = false;
			this.login = data;
			this.cargarAccesos();					
		}).catch(
			data => {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data})
			}
		);		
	},	
	methods: {
		cargarAccesos() {
			this.loading = true;
			UserService.getAccesosXLogin(this.$store.state.auth.currentAppCtx, this.login.login).then(data => {
				this.loading = false;
				this.grupos = data;
			}).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);
		},
		setPermiso(event, acceso) {
			this.loading = true;
			
			UserService.setAccesoLogin(this.$store.state.auth.currentAppCtx, this.login, acceso).then(() => {
				this.loading = false;
			}).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);			
		},
		openNew() {
			this.login = {
				id_entidad: "",
				id_entidad_tipo: 1,
				codigo: "",
				contacto: "",
				nombres: "",
				apellidos: "",
				identificacion1: "",
				identificacion2: "",
				tel_residencia: "",
				direccion_residencia: "",
				tel_movil: "",
				tel_trabajo: "",
				fax_trabajo: "",
				direccion_trabajo: "",
				email1: "",
				email2: "",
				nombre_comercial: "",
				razon_social: "",
				activo: "S",
				activoDisplay: true				
			}; 
			this.entidadUpdate++;
		},		
		save() {						
			this.login.activo = this.login.activoDisplay ? 'S': 'N';
			this.loading = true;
			UserService.registrarEntidad(this.$store.state.auth.currentAppCtx, this.entidad).then(data => {
				this.loading = false;
				this.entidad.id_entidad = data.id_entidad;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'Los cambios han sido guardados con éxito!', life: 3000});									
			}).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);
		},
		fetchData () {
			this.error = this.post = null;
			this.id_login = this.id;
			this.loading = true;
			UserService.getEntidades(this.$store.state.auth.currentAppCtx, this.id_entidad).then(data => {				
				this.loading = false;
				this.entidad = data;
				this.entidad.activoDisplay = (data.activo == 'S');
				this.entidadUpdate++;
			}
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		}			
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
